import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "welcome"
    }}>{`Welcome`}</h1>
    <Alert type="success" mdxType="Alert">In love with JavaScript</Alert>
    <h2 {...{
      "id": "how-are-you"
    }}>{`How Are You?`}</h2>
    <p>{`Just bring your `}<inlineCode parentName="p">{`JS`}</inlineCode>{` skills to the new level`}</p>
    <h2 {...{
      "id": "some-code"
    }}>{`SOME CODE`}</h2>
    <pre><code parentName="pre" {...{}}>{``}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://rynkov.eu"
      }}><strong parentName="a">{`rynkov.eu`}</strong></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      